<template>
    <div>
        <RMAHeader navBarColor="#ffffff"></RMAHeader>
        <div class="main-wrapper-image" id="backgroundImage">
            <RMACenterBox continueButtonText="'KOM I GANG'" buttomBoxText="'Du kan returnere dit køb ved hjælp af nogle få, enkle trin med BEAUTYCOS. Bemærk venligst, at nogle leveringsvalg kan kræve en printer.'" mainBoxText="'Vi introducerer en smartere måde at returnere på...'"></RMACenterBox>
            <RMAFooter></RMAFooter>
            <button @click="Test">yo</button>
        </div>
    </div>
</template>
<script>
import RMAHeader from '../RMAHeader';
import RMAFooter from '../RMAFooter'
import RMACenterBox from './RMACenterBox'

export default {
  name: 'RMAPreview',
  components: {
    RMAHeader,
    RMAFooter,
    RMACenterBox,
  },
  props: {
    previewBackgroundImage: String,
  },
  data() {
    return {};
  },
  mounted() {
    this.SetBackgroundImage()
    if(this.$router.params) console.log(this.$router.params, 'yo')
  },
  methods: {
    SetBackgroundImage() {
      let pathToBackgroundImage = 'null';
      //api call

      if(!pathToBackgroundImage)
      {
        pathToBackgroundImage = 'https://www.beautycos.dk/media/storelocator/image/b/e/beautycos-kbh.jpg'
        
        pathToBackgroundImage = `url('${pathToBackgroundImage}')`;

        document.getElementById('backgroundImage').style.backgroundImage =  pathToBackgroundImage
      }
    },
    Test() {
      console.log(this.$router.params, 'yo')
    }
  }
};
</script>
<style lang="scss" scoped>
.main-wrapper-image {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-image: url('../../../../../../public/img/RMAtest.png');
}
</style>
