<template>
  <a class="item" :h-ref="'/?lang=' + languageCode">
    <slot />
  </a>
</template>
<script>
export default {
  props: {
    languageCode: String,
  }
}
</script>
<style>
.item {
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  color: #1f2937;
  font-weight: 500;
  cursor: pointer;
}
.item:hover {
  background-color: #e5e7eb;
}
</style>
