<template>
    <footer class="footer">
        © PackShip {{ year }}
    </footer>
</template>
<script>
  export default {
    name: 'Footer',
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style lang="scss" scoped>
.footer {
    width: 100vw;
    bottom: 0;
    left: 0;
    position: fixed;
}
</style>