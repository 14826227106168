<template>
  <nav class="header-bar">
    <div class="logo-container">
      <a
        ><img class="logo" src="../../../../assets/images/logo.png" alt="Logo"
      /></a>
    </div>
    <DropdownWrapper>
      <template slot="toggler">
        <button class="toggle-dropdown">
          {{ $t('Language') }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="arrow"
          >
            <path
              d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
            ></path>
          </svg>
        </button>
      </template>
      <DropdownContent>
        <DropdownItem v-for="(lang, index) in this.languages" :key="index" :languageCode="lang.code">
          <Flag
            :code="lang.code"
            size="small"
            type="language"
            :title="CountryName(lang.country)"
          />
          <div class="lang-text">
            {{ lang.label }}
          </div>
        </DropdownItem>
      </DropdownContent>
    </DropdownWrapper>
  </nav>
</template>
<script>
import Flag from '@/components/snippets/Flag';
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';
import DropdownContent from '@/components/snippets/DropdownFlag/DropdownContent';
import DropdownItem from '@/components/snippets/DropdownFlag/DropdownItem';
import DropdownWrapper from '@/components/snippets/DropdownFlag/DropdownWrapper';

export default {
  name: 'RMAHeader',
  components: {
    Flag,
    DropdownContent,
    DropdownItem,
    DropdownWrapper,
  },
  mixins: [BPA],
  props: {
    navBarColor: String,
  },
  data() {
    return {
      rma: {
        enabled: false,
        language: 'Language',
      },
      languages: [],
    };
  },
  computed: {

  },
  mounted() {
    console.log(this.navBarColor, 'color');
    document.documentElement.style.setProperty(
      '--navBarColor',
      this.navBarColor
    );
  },
  async created() {
    let storage = BPA.util.storage;
    let languages = storage.get('languages');
    let company = BPA.util.GetCompany();
    if (!Object.keys(languages).length || !(company in languages)) {
      let locales = await this.GetLanguageOrderCount();
      languages[company] = [];
      for (let locale in locales) {
        let option = { code: locale };
        if (/nb/.test(locale)) {
          locale = locale.replace('nb', 'no');
        }
        let code = locale.split('_');
        option.label = this.LanguageName(code[0], 'en');
        option.language = code[0];
        option.country = code[1];
        languages[company].push(option);
      }
      storage.set('languages', languages);
    }
    this.languages = languages[company] || [];

    if (this.languages.length) {
      console.log();
    }
  },
  methods: {
    Alphabetize(list, prop) {
      return Tool.Alphabetize(list, prop);
    },
    CountryName(country_code = '', locale) {
      return Tool.CountryName(country_code, locale);
    },
    LanguageName(country_code = '', locale) {
      return Tool.LanguageName(country_code, locale);
    },
    async GetLanguageOrderCount(company_code) {
      return await BPA.api
        .GetLanguageOrderCount(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => response.result || {})
        .catch((e) => e);
    },
  },
};
</script>
<style lang="scss" scoped>
$navBarColor: white;

.header-bar {
  top: 0;
  left: 0;
  background-color: var(--navBarColor, $navBarColor);
  width: 100vw;
  flex-basis: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  height: 3rem;
  border-bottom: 1px solid #d1d1d1;
}
.logo-container {
  height: 70%;
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
}
.logo {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
}
.toggle-dropdown {
  display: flex;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 1.25rem;
  font-weight: 600;
  align-items: center;
  border: none;
  border-bottom: 1px solid #eaeaea;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 10px;
}
.arrow {
  margin-left: 0.25rem;
  color: #374151;
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
}
.lang-text {
  margin-left: 5px;
}
</style>
