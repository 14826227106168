<template>
    <transition name="dropdown-content">
        <div v-if="active" class="dropdown-content">
          <slot />
        </div>
    </transition>
</template>
<script>
export default {
  name: 'DropdownContent',
  inject: ['sharedState'],
  computed: {
    active() {
      return this.sharedState.active;
    },
  },
};
</script>
<style>
.dropdown-content {
  position: absolute;
  right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: #ffffff;
  transform-origin: top right;
  width: 12rem;
  border-radius: 0.5rem;
  border-width: 1px;
}
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
