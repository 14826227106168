<template>
  <div>
    <div class="box-container">
      <div class="center-box">
        <img class="box-logo" id="boxLogo" />
        <p class="main-text"></p>
        <button class="continue-btn"></button>
      </div>
      <div class="bottom-box"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RMACenterBox',
  components: {},
  mixins: [],
  props: {
    boxLogo: String,
    mainBoxText: String,
    continueButtonText: String,
    buttomBoxText: String,
  },
  data() {
    return {
      rma: {
        enabled: false,
        language: 'Language',
      },
      languages: [],
    };
  },
  computed: {},
  mounted() {
    document.documentElement.style.setProperty('--boxLogo', this.boxLogo);
    document.documentElement.style.setProperty(
      '--mainBoxText',
      this.mainBoxText
    );
    document.documentElement.style.setProperty(
      '--continueButtonText',
      this.continueButtonText
    );
    document.documentElement.style.setProperty(
      '--buttomBoxText',
      this.buttomBoxText
    );
    this.SetBoxLogo();
  },
  methods: {
    SetBoxLogo() {
      let pathToBackgroundImage = null;
      //api call

      if (!pathToBackgroundImage) {
        pathToBackgroundImage =
          'https://www.beautycos.dk/media/wysiwyg/logo_red.png';

        document.getElementById('boxLogo').src = pathToBackgroundImage;
      }

      console.log(document.getElementById('boxLogo'));
    },
  },
};
</script>
<style lang="scss">
$mainBoxText: 'Vi introducerer en smartere måde at returnere på...';
$continueButtonText: 'KOM I GANG';
$buttomBoxText: 'Du kan returnere dit køb ved hjælp af nogle få, enkle trin med BEAUTYCOS. Bemærk venligst, at nogle leveringsvalg kan kræve en printer.';

.box-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}
.center-box {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.main-text {
  margin-top: 20px;
  margin-bottom: 20px;
}
.main-text::before {
  content: var(--mainBoxText, $mainBoxText);
  font-size: 1.2rem;
}
.continue-btn {
  background-color: #7a9e93;
  padding: 20px 70px;
  border-radius: 0;
  outline: none;
  appearance: none;
  border: none;
  font-family: 'Founders Grotesk', 'Open sans', 'sans-serif';

  &:not(:disabled) {
    cursor: pointer;
  }
}
.continue-btn::before {
  content: var(--continueButtonText, $continueButtonText);
  font-size: 1rem;
  color: white;
  font-weight: bold;
}
.bottom-box {
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: x-small;
}
.bottom-box::before {
  content: var(--buttomBoxText, $buttomBoxText);
}
@media only screen and (max-width: 768px) {
  .center-box {
    padding: 20px;
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
  }
  .bottom-box {
    padding: 20px;
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
  }
}
</style>
